<template>
  <section class="mb-16">
    <v-row class="d-flex align-center">
      <v-col cols="12" sm="8">
        <p class="text-h5 secondary--text">Registro de etapa</p>
      </v-col>
      <v-col cols="12" sm="4" class="text-center">
        <p>Cantidad restante de pagar</p>
        <h2>
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }).format(montoRestante.monto_restante)
          }}
        </h2>
      </v-col>
    </v-row>

    <v-form ref="formEtapa">
      <h3 class="mt-8 mb-2">Datos de la etapa</h3>
      <v-divider />

      <v-row class="mt-4">
        <v-col cols="12" sm="6">
          <v-menu
            v-model="menuRecepcion"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                label="Fecha probable de recepción *"
                v-model="formattedDateRecepcion"
                :error-messages="fechaProbableError"
                @blur="$v.datosEtapa.fecha_probable_recepcion.$touch()"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datosEtapa.fecha_probable_recepcion"
              locale="es"
              no-title
              color="primary"
              @input="menuRecepcion = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <money-text-field-component
            ref="futuroPago"
            label="Cantidad a pagar *"
            v-model="datosEtapa.futuro_pago"
            outlined
            :decimals="8"
            :error-messages="futuroPagoError"
            @input="$v.datosEtapa.futuro_pago.$touch()"
          />
        </v-col>

        <v-col cols="12" sm="12">
          <v-textarea
            v-model="datosEtapa.descripcion_entrega"
            label="Descripción de entrega *"
            :error-messages="descripcionEntregaError"
            @input="$v.datosEtapa.descripcion_entrega.$touch()"
            outlined
            no-resize
            counter="500"
            maxlength="500"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row class="px-3 mb-6" v-if="this.id_etapa && puedeRegistrarEtapa">
        <span> Seleccione los lotes o items a entregar en la presente etapa: </span>

        <!-- expansion panel -->
        <v-expansion-panels v-model="exp_lote_var" flat>
          <v-expansion-panel v-for="(lote, i) in list_lote_var" :key="i">
            <v-expansion-panel-header>
              {{ lote.nombre }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :expanded="true" class="bgMinsal">
              <data-table-component
                :headers="HEADERS_LOTES_CONST"
                :items="lote.sub_procesos"
                :inhabilitar_paginacion="true"
                :tiene_paginacion="false"
              >
                <!-- slot de cantidad entregada -->
                <template v-slot:[`item.cantidad_entregada`]="{ item }">
                  <div v-if="item.cantidad_total !== 0">
                    <v-text-field
                      class="py-2"
                      v-if="item.cantidad_total !== 0"
                      v-model.number="item.cantidad_entregada"
                      outlined
                      dense
                      hide-details
                      @input="FocusCantidadEntregadaFtn(item)"
                    />
                  </div>
                  <span v-else> Se ha alcanzado la cantidad máxima de entrega </span>
                </template>
                <template v-slot:[`item.monto`]="{ item }">
                  <v-text-field
                    class="py-2"
                    v-if="item.cantidad_total !== 0"
                    v-model="item.monto"
                    outlined
                    dense
                    hide-details
                    @input="FocusMontoFtn(item)"
                  />
                </template>
              </data-table-component>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <div v-if="!cdfLoading && !contratoLoading && !puedeRegistrarEtapa">
        <v-alert text prominent color="warning" icon="mdi-alert">
          <p class="text-h5 mb-0">No se puede registrar la entrega de la etapa</p>
          <p class="mb-0">
            Aún no se ha ingresado el presupuesto votado. Para mayor información sobre la
            carga de presupuesto votado consulte con la UFI de su institución.
          </p>
        </v-alert>
      </div>

      <div class="d-flex flex-column ma-8 pb-8">
        <p class="secondary--text text-h5 text-center">Certificación de fondos</p>
        <v-row>
          <v-col>
            <p class="secondary--text text-center">
              Certificado:
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                }).format(certificacion?.certificado || 0)
              }}
            </p>
          </v-col>
          <v-col>
            <p class="secondary--text text-center">
              Utilizado:
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                }).format(certificacion?.utilizado || 0)
              }}
            </p>
          </v-col>
          <v-col>
            <p class="secondary--text text-center">
              Disponible:
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                }).format(certificacion?.disponible || 0)
              }}
            </p>
          </v-col>
        </v-row>
      </div>

      <v-row v-if="id_etapa == null">
        <v-col cols="12" sm="6" md="3">
          <v-btn outlined block color="secondary" @click="$router.back()">
            Regresar
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn
            block
            type="button"
            @click="guardarDatoEtapa(true, true)"
            color="secondary"
          >
            Siguiente
          </v-btn>
        </v-col>
      </v-row>

      <div v-if="this.id_etapa && puedeRegistrarEtapa">
        <v-row class="mt-8 mb-2">
          <v-col cols="12" sm="9">
            <h3>Entrega de la etapa</h3>
          </v-col>
          <v-col cols="12" sm="3" v-if="id_movimiento_etapa == null">
            <v-btn outlined block dense color="secondary" @click="regresar()">
              Registrar entrega después
            </v-btn>
          </v-col>
        </v-row>
        <v-divider />

        <v-row class="mt-2">
          <v-col cols="12" md="4" class="mt-4">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  label="Fecha de recepción *"
                  v-model="formattedDate"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="fechaRecepcionError"
                  @blur="$v.entregaEtapa.fecha_recepcion.$touch()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="entregaEtapa.fecha_recepcion"
                locale="es"
                no-title
                color="primary"
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" class="mt-4">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  readonly
                  outlined
                  v-model="entregaEtapa.hora_recepcion"
                  label="Hora de recepción *"
                  v-on="on"
                  v-bind="attrs"
                  :error-messages="horaRecepcionError"
                  @blur="$v.entregaEtapa.hora_recepcion.$touch()"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                format="24hr"
                full-width
                v-model="entregaEtapa.hora_recepcion"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <p class="mb-0">Ponderación *</p>
            <v-rating
              v-model="entregaEtapa.ponderacion"
              half-increments
              background-color="secondary"
              color="secondary"
              size="35"
            ></v-rating>
            <p
              v-if="ponderacionError.length > 0"
              class="red--text ml-4"
              style="font-size: 12px"
            >
              {{ ponderacionError[0] }}
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="12">
            <v-textarea
              v-model="entregaEtapa.observaciones"
              label="Observaciones"
              outlined
              :error-messages="observacionesError"
              @input="$v.entregaEtapa.observaciones.$touch()"
              no-resize
              counter="500"
              maxlength="500"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3">
            <v-select
              label="Tipo de movimiento *"
              :items="tipoMovimientoContrato"
              v-model="entregaEtapa.tipo_movimiento"
              outlined
              item-text="nombre"
              item-value="id"
              :error-messages="tipoMovimientoContratoError"
              @blur="$v.entregaEtapa.tipo_movimiento.$touch()"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              label="Forma de pago *"
              :items="formaPagos"
              v-model="entregaEtapa.forma_pago"
              outlined
              item-text="nombre"
              item-value="id"
              :error-messages="formaPagoError"
              @blur="$v.entregaEtapa.forma_pago.$touch()"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <money-text-field-component
              ref="pago"
              label="Pago *"
              v-model="entregaEtapa.pago"
              outlined
              :decimals="8"
              :error-messages="pagoErrors"
              @input="$v.entregaEtapa.pago.$touch()"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div v-if="!info_comprobante_pago.status" class="d-flex flex-row">
              <v-file-input
                accept="application/pdf"
                clearable
                label="Comprobante de pago *"
                outlined
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                v-model="entregaEtapa.comprobante_pago"
                :error-messages="comprobantePagoErrors"
                @blur="$v.entregaEtapa.comprobante_pago.$touch()"
              />
              <v-btn
                v-if="info_comprobante_pago?.id"
                class="mt-3 ml-2"
                icon
                @click="info_comprobante_pago.status = true"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div v-else class="d-flex align-center flex-row">
              <span>Comprobante de pago</span>
              <v-btn
                icon
                v-if="info_comprobante_pago.status"
                @click="info_comprobante_pago.status = false"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                v-if="info_comprobante_pago?.ruta"
                @click="DownloadFileFtn(info_comprobante_pago?.ruta)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div v-if="!info_acta_recepcion.status" class="d-flex flex-row">
              <v-file-input
                accept="application/pdf"
                clearable
                label="Acta de recepción *"
                outlined
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                v-model="entregaEtapa.acta_recepcion"
                :error-messages="actaRecepcionErrors"
                @blur="$v.entregaEtapa.acta_recepcion.$touch()"
              />
              <v-btn
                v-if="info_acta_recepcion?.id"
                class="mt-3 ml-2"
                icon
                @click="info_acta_recepcion.status = true"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div v-else class="d-flex align-center flex-row">
              <span>Acta de recepción</span>
              <v-btn
                icon
                v-if="info_acta_recepcion.status"
                @click="info_acta_recepcion.status = false"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                v-if="info_acta_recepcion?.ruta"
                @click="DownloadFileFtn(info_acta_recepcion?.ruta)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div v-if="!info_acta_inconform.status" class="d-flex flex-row">
              <v-file-input
                accept="application/pdf"
                clearable
                label="Acta de inconformidad (Opcional)"
                outlined
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                v-model="entregaEtapa.acta_inconform"
                :error-messages="actaInconformErrors"
                @blur="$v.entregaEtapa.acta_inconform.$touch()"
              />
              <v-btn
                v-if="info_acta_inconform?.id"
                class="mt-3 ml-2"
                icon
                @click="info_acta_inconform.status = true"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div v-else class="d-flex align-center flex-row">
              <span>Acta de inconformidad</span>
              <v-btn
                icon
                v-if="info_acta_inconform.status"
                @click="info_acta_inconform.status = false"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                v-if="info_acta_inconform?.ruta"
                @click="DownloadFileFtn(info_acta_inconform?.ruta)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div v-if="$route.params.idEtapa">
          <v-row>
            <v-col cols="12" sm="12" md="11">
              <p class="text-h5 secondary--text">Evaluación del proveedor</p>
              <span class="text-h6">
                Apóyanos evaluando como ha sido tu experiencia con este proveedor, de esta
                forma el resto de instituciones tendrán más herramientas a la hora de
                tomar decisiones a la vez que mantenemos un buen estándar de calidad
                juntos.
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <p class="mb-0">Ponderación</p>
              <v-rating
                v-model="reseña.ponderacion"
                half-increments
                background-color="secondary"
                color="secondary"
                size="35"
              ></v-rating>
              <p
                v-if="ponderacionError1.length > 0"
                class="red--text ml-4"
                style="font-size: 12px"
              >
                {{ ponderacionError1[0] }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="9">
              <v-textarea
                label="Reseña de la entrega o del proveedor (opcional)"
                outlined
                v-model="reseña.descripcion"
                counter="500"
                maxlength="500"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </div>

        <v-row align="center" v-if="false">
          <v-col cols="12" md="6">
            <v-btn
              class="secondary"
              :disabled="activarActaRecepcion"
              @click="dialogo_acta = true"
              block
            >
              <v-icon color="white">mdi-file-document</v-icon> Generar acta de recepción
            </v-btn>
            <v-btn
              block
              class="secondary mt-2 mb-2"
              @click="downloadDoc"
              v-if="$route.params.idEtapa && id_documento_acta"
            >
              <v-icon color="white">mdi-download</v-icon> Descargar acta de recepción
            </v-btn>
            <v-file-input
              outlined
              class="mt-4"
              label="Acta de recepción"
              v-model="documento_acta_recepcion"
              prepend-icon=""
              accept="application/pdf"
              prepend-inner-icon="mdi-paperclip"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6" v-if="false">
            <v-file-input
              color="red"
              :disabled="activarActaRecepcion"
              outlined
              block:rules="[(v) => !!v || 'Este campo es requerido']"
              accept="application/pdf"
              prepend-inner-icon="mdi-paperclip"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="12" sm="6" md="3">
            <v-btn outlined block color="secondary" @click="regresar()">
              {{ id_movimiento_etapa == null ? "Registrar entrega después" : "Volver" }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-btn
              block
              type="button"
              :disabled="id_etapa === null"
              @click="preGuardarEtapa()"
              color="secondary"
            >
              {{
                id_movimiento_etapa == null && id_proveedor === null
                  ? "Registrar entrega"
                  : "Guardar cambios"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <ConfirmationDialogComponent
        :show="preValidacion"
        :btnConfirmar="
          id_movimiento_etapa == null ? 'Registrar entrega' : 'Guardar cambios'
        "
        :title="
          id_movimiento_etapa == null
            ? '¿Deseas registrar la entrega de esta etapa?'
            : '¿Deseas actualizar los datos de la entrega?'
        "
        message="De confirmarse la siguiente acción, los cambios no serán reversibles."
        @close="preValidacion = false"
        @confirm="(preValidacion = false), guardarDatoEtapa(false, true)"
      />

      <app-dialog v-model="dialogo_acta" :max-width="800" title="Acta de recepción">
        <template v-slot:content>
          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="menuFechaActaRecepcion"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    label="Fecha del acta *"
                    v-model="formattedDateActaRecepcion"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="acta_recepcion.fecha"
                  locale="es"
                  no-title
                  color="primary"
                  @input="menuFechaActaRecepcion = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                v-model="acta_recepcion.comentario"
                label="Expresión sobre la satisfacción o no de la obra, bien o servicio *"
                counter="500"
                maxlength="500"
                auto-grow
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="3">
              <v-btn text @click="dialogo_acta = false">Cancelar</v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn class="primary" :disabled="showButtonSave" @click="preGuardarEtapa()"
                >Guardar e imprimir</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </app-dialog>
    </v-form>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { helpers, required, requiredIf } from "vuelidate/lib/validators";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import LegacyValidations from "@/utils/legacy-validations";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import moment from "moment";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

const priceDecimals = helpers.regex("priceDecimals", /^[0-9]+([.][0-9]{1,8})?$/);

const txtArea = helpers.regex("txtArea", /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/);

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function validPago(value) {
  return value ? value <= Number(this.certificacion?.disponible) : true;
}

export default {
  name: "entregaEtapaCompraView",
  components: {
    ConfirmationDialogComponent,
    MoneyTextFieldComponent,
    DataTableComponent,
  },
  data() {
    return {
      preValidacion: false,
      tab: null,
      actaModal: false,
      dialogo_acta: false,
      alertaCantidadEntregadaMostrada: false,
      params: {},
      isContentExpanded: true,
      entregaEtapa: {
        fecha_recepcion: null,
        ponderacion: null,
        hora_recepcion: null,
        observaciones: null,
        tipo_movimiento: null,
        forma_pago: null,
        pago: 0,
        comprobante_pago: null,
        acta_recepcion: null,
        acta_inconform: null,
      },

      info_comprobante_pago: {
        id: null,
        ruta: null,
        status: null,
      },
      info_acta_recepcion: {
        id: null,
        ruta: null,
        status: null,
      },
      info_acta_inconform: {
        id: null,
        ruta: null,
        status: null,
      },
      init_documents: false,

      datosEtapa: {
        fecha_probable_recepcion: null,
        futuro_pago: 0,
        descripcion_entrega: null,
      },
      reseña: {
        ponderacion: null,
        descripcion: null,
      },
      montoRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => {
          return (
            (!!v && Number(v.toString().replaceAll(",", "")) > 0) ||
            "Este campo debe ser mayor a 0 "
          );
        },
        (v) =>
          /^-?\d|,((\.[0-9]{0,2})?)$/.test(v) ||
          "Debe ingresar un valor monetario válido",
      ],
      menu: null,
      menu2: null,
      menuRecepcion: null,
      acta_recepcion: {
        fecha: null,
        comentario: null,
      },
      menuFechaActaRecepcion: false,
      formaPagos: [],
      tipoMovimientoContrato: [],
      documento_acta_recepcion: null,
      documento_acta_inconformidad: null,
      id_etapa: null,
      id_movimiento_etapa: null,
      id_documento_acta: null,
      montoRestante: {},
      id_proveedor: null,
      idEvaluacion: null,
      cdf: [],
      exp_lote_var: [],
      list_lote_var: [],

      HEADERS_LOTES_CONST: [
        {
          text: "OBS",
          align: "center",
          value: "especificacion",
        },
        {
          text: "Unidad de medida",
          align: "center",
          value: "Presentacion.nombre",
        },
        {
          text: "Cantidad total",
          align: "center",
          value: "cantidad_total",
        },
        {
          text: "Cantidad entregada",
          align: "center",
          value: "cantidad_entregada",
          sortable: false,
        },
        {
          text: "Monto",
          align: "center",
          value: "monto",
          sortable: false,
        },
      ],
      contrato: null,
      timer: null,
      certificacion: {},
      cdfLoading: false,
      contratoLoading: false,
    };
  },
  validations: {
    datosEtapa: {
      fecha_probable_recepcion: { required },
      futuro_pago: { required, priceDecimals },
      descripcion_entrega: { required, txtArea },
    },
    entregaEtapa: {
      fecha_recepcion: { required },
      ponderacion: { required },
      hora_recepcion: { required },
      observaciones: { txtArea },
      tipo_movimiento: { required },
      forma_pago: { required },
      pago: { required, priceDecimals, validPago },
      comprobante_pago: {
        required: requiredIf(function (value) {
          return this.info_comprobante_pago.id == null;
        }),
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
      acta_recepcion: {
        required: requiredIf(function (value) {
          return this.info_acta_recepcion.id == null;
        }),
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
      acta_inconform: {
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    },
    reseña: {
      ponderacion: { required },
    },
  },
  computed: {
    ...mapState(["selectedUnidad"]),
    ...mapState("agregarInsumo", ["selectedProvider"]),
    fechaProbableError() {
      const errors = [];
      if (!this.$v.datosEtapa.fecha_probable_recepcion.$dirty) return errors;
      !this.$v.datosEtapa.fecha_probable_recepcion.required &&
        errors.push("Fecha probable de recepción es requerido");
      return errors;
    },
    futuroPagoError() {
      const errors = [];
      if (!this.$v.datosEtapa.futuro_pago.$dirty) return errors;
      !this.$v.datosEtapa.futuro_pago.required &&
        errors.push("Cantidad a pagar es requerido");
      !this.$v.datosEtapa.futuro_pago.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      // !this.$v.datosEtapa.futuro_pago.minValue &&
      //   errors.push("El campo debe ser mayor a 0");
      return errors;
    },
    descripcionEntregaError() {
      const errors = [];
      if (!this.$v.datosEtapa.descripcion_entrega.$dirty) return errors;
      !this.$v.datosEtapa.descripcion_entrega.required &&
        errors.push("Descripción de entrega es requerido");
      !this.$v.datosEtapa.descripcion_entrega.txtArea &&
        errors.push("El campo no permite caracteres especiales");
      return errors;
    },
    fechaRecepcionError() {
      const errors = [];
      if (!this.$v.entregaEtapa.fecha_recepcion.$dirty) return errors;
      !this.$v.entregaEtapa.fecha_recepcion.required &&
        errors.push("Fecha de recepción es requerido");
      return errors;
    },
    horaRecepcionError() {
      const errors = [];
      if (!this.$v.entregaEtapa.hora_recepcion.$dirty) return errors;
      !this.$v.entregaEtapa.hora_recepcion.required &&
        errors.push("Hora de recepción es requerido");
      return errors;
    },
    ponderacionError() {
      const errors = [];
      if (!this.$v.entregaEtapa.ponderacion.$dirty) return errors;
      !this.$v.entregaEtapa.ponderacion.required &&
        errors.push("Ponderación es requerido");
      return errors;
    },
    ponderacionError1() {
      const errors = [];
      if (!this.$v.reseña.ponderacion.$dirty) return errors;
      !this.$v.reseña.ponderacion.required && errors.push("Ponderación es requerido");
      return errors;
    },
    observacionesError() {
      const errors = [];
      if (!this.$v.entregaEtapa.observaciones.$dirty) return errors;
      !this.$v.entregaEtapa.observaciones.txtArea &&
        errors.push("El campo no permite caracteres especiales");
      return errors;
    },
    tipoMovimientoContratoError() {
      const errors = [];
      if (!this.$v.entregaEtapa.tipo_movimiento.$dirty) return errors;
      !this.$v.entregaEtapa.tipo_movimiento.required &&
        errors.push("Tipo de movimiento es requerido");
      return errors;
    },
    formaPagoError() {
      const errors = [];
      if (!this.$v.entregaEtapa.forma_pago.$dirty) return errors;
      !this.$v.entregaEtapa.forma_pago.required &&
        errors.push("Forma de pago es requerido");
      return errors;
    },
    pagoErrors() {
      const errors = [];
      if (!this.$v.entregaEtapa.pago.$dirty) return errors;
      !this.$v.entregaEtapa.pago.required && errors.push("Pago es requerido");
      !this.$v.entregaEtapa.pago.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      !this.$v.entregaEtapa.pago.validPago &&
        errors.push(
          `El pago no puede ser mayor que el financiamiento disponible ($${this.certificacion?.disponible})`
        );

      return errors;
    },
    comprobantePagoErrors() {
      const errors = [];
      if (!this.$v.entregaEtapa.comprobante_pago.$dirty) return errors;
      !this.$v.entregaEtapa.comprobante_pago.required &&
        errors.push("Comprobante de pago es requerido");
      !this.$v.entregaEtapa.comprobante_pago.fileNameValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.entregaEtapa.comprobante_pago.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
    actaRecepcionErrors() {
      const errors = [];
      if (!this.$v.entregaEtapa.acta_recepcion.$dirty) return errors;
      !this.$v.entregaEtapa.acta_recepcion.required &&
        errors.push("Acta de recepción es requerido");
      !this.$v.entregaEtapa.acta_recepcion.fileNameValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.entregaEtapa.acta_recepcion.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
    actaInconformErrors() {
      const errors = [];
      if (!this.$v.entregaEtapa.acta_inconform.$dirty) return errors;
      !this.$v.entregaEtapa.acta_inconform.fileNameValid &&
        errors.push("El archivo debe ser PDF");
      !this.$v.entregaEtapa.acta_inconform.fileSizeValid &&
        LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },

    formattedDate() {
      if (this.entregaEtapa.fecha_recepcion) {
        const fmDate = moment(
          this.entregaEtapa.fecha_recepcion,
          "YYYY-MM-DD",
          true
        ).isValid();
        if (fmDate) {
          return moment(this.entregaEtapa.fecha_recepcion, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          );
        } else {
          return this.entregaEtapa.fecha_recepcion;
        }
      } else return "";
    },
    formattedDateRecepcion() {
      if (this.datosEtapa.fecha_probable_recepcion) {
        const fmDate = moment(
          this.datosEtapa.fecha_probable_recepcion,
          "YYYY-MM-DD",
          true
        ).isValid();
        if (fmDate) {
          return moment(this.datosEtapa.fecha_probable_recepcion, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          );
        } else {
          return this.datosEtapa.fecha_probable_recepcion;
        }
      } else return "";
    },
    formattedDateActaRecepcion() {
      if (this.acta_recepcion.fecha) {
        return moment(this.acta_recepcion.fecha).format("DD/MM/YYYY");
      } else return "";
    },
    activarActaRecepcion() {
      return !(
        this.entregaEtapa.ponderacion != null &&
        this.entregaEtapa.fecha_recepcion != null &&
        this.entregaEtapa.hora_recepcion != null &&
        this.entregaEtapa.tipo_movimiento != null &&
        this.entregaEtapa.forma_pago != null &&
        this.entregaEtapa.pago != null &&
        this.entregaEtapa.pago != ""
      );
    },
    showButtonSave() {
      return !(
        this.acta_recepcion.fecha != null &&
        this.acta_recepcion.comentario != null &&
        this.acta_recepcion.comentario != ""
      );
    },
    currencyMask() {
      if (this.datosEtapa.futuro_pago) {
        return createNumberMask({
          prefix: "",
          allowDecimal: true,
          includeThousandsSeparator: true,
          allowNegative: false,
        });
      } else {
        return "";
      }
    },
    tipoPresupuesto() {
      switch (this.contrato?.version) {
        case 1:
          return "proyecto";
        case 2:
          return "votado";
        default:
          return null;
      }
    },
    cdfCount() {
      return this.cdf.length;
    },
    /**
     * El registro de etapas debe ser si el presupuesto es votado o si hay cifrados
     */
    puedeRegistrarEtapa() {
      return this.tipoPresupuesto === "votado" || this.cdfCount > 0;
    },
  },
  methods: {
    ...mapActions("seguimientoOrden", ["getContrato"]),

    async preGuardarEtapa() {
      this.$v.entregaEtapa.$touch();
      let cantidadEntregada = null;
      let cantidadTotal = null;
      if (
        this.list_lote_var.length > 0 &&
        this.list_lote_var[0].sub_procesos.length > 0
      ) {
        cantidadEntregada = this.list_lote_var[0].sub_procesos[0].cantidad_entregada;
        cantidadTotal = this.list_lote_var[0].sub_procesos[0].cantidad_total;
      }

      if (!this.id_etapa && cantidadTotal !== 0) {
        if (
          cantidadEntregada === null ||
          cantidadEntregada === undefined ||
          cantidadEntregada === ""
        ) {
          this.temporalAlert({
            show: true,
            type: "warning",
            message:
              "Debe completar el campo de cantidad entregada en selección de lotes o items a entregar, para poder continuar.",
          });
          return;
        }
      }

      if (!this.$v.entregaEtapa.$invalid) {
        this.preValidacion = true;
      }
    },
    async generarActaRecepcion() {
      const params = {
        id_institucion: this.selectedUnidad.instituciones.id,
        expresion: this.acta_recepcion.comentario,
        fecha_recepcion: this.acta_recepcion.fecha,
        id_etapa: this.id_etapa,
      };

      const { status, data } = await this.services.ContratoService.generarActaDeRecepcion(
        this.$route.params.idContrato,
        params
      ).catch((e) => {
        this.temporalAlert({
          show: true,
          type: "error",
          message: `Ya existe un acta de recepcion para este contrato`,
        });
      });

      if (status === 200) {
        this.downloadActa(data, `ActaRecepcion_${this.$route.params.idContrato}.pdf`);
      }

      this.dialogo_acta = false;
      this.acta_recepcion_loading = false;
    },
    downloadActa(data, filename = "ActaInconformidad.pdf") {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },
    async getTiposPagos() {
      const { data, status } = await this.services.ContratoService.getTiposPagos();
      if (status == 200) this.formaPagos = data;
    },

    async getTipoMovimientoContrato() {
      const { data, status } = await this.services.ContratoService.getMovimientoContrato();
      if (status === 200) {
        this.tipoMovimientoContrato = data;
      }
      if (this.tipoMovimientoContrato.length > 0) {
        this.entregaEtapa.tipo_movimiento = this.tipoMovimientoContrato[0].id;
      }
    },

    async guardarDatoEtapa(sendReturn = false, noValidDocument = false) {
      this.$v.datosEtapa.$touch();
      if (this.$v.datosEtapa.$invalid) {
        this.temporalAlert({
          show: true,
          message: "Debe llenar todos los campos requeridos",
          type: "warning",
        });

        return;
      }

      const etapaContrato = new FormData();

      this.list_lote_var.forEach((lote) => {
        lote.sub_procesos.forEach((sub_proceso) => {
          let obsData = {};

          if (!this.id_etapa) {
            obsData = {
              id_sub_proceso_obs: sub_proceso.id,
              cantidad: 0,
              porcentaje: 0,
              monto: 0,
            };
          } else {
            obsData = {
              id_sub_proceso_obs: sub_proceso.id,
              cantidad: Number(sub_proceso.cantidad_entregada) || 0,
              porcentaje: Number(
                (sub_proceso.cantidad_entregada * 100) / sub_proceso.cantidad_inicial
              ),
              monto: sub_proceso.monto,
            };
          }
          etapaContrato.append("obs", JSON.stringify(obsData));
        });
      });

      etapaContrato.append("cantidad_pagar", this.datosEtapa.futuro_pago);
      etapaContrato.append("descripcion", this.entregaEtapa.observaciones);
      etapaContrato.append(
        "fecha_inicio",
        `${this.datosEtapa.fecha_probable_recepcion} 00:00:00`
      );
      etapaContrato.append("descripcion_entrega", this.datosEtapa.descripcion_entrega);

      if (
        +this.datosEtapa.futuro_pago > +this.montoRestante.monto_restante &&
        this.id_movimiento_etapa == null
      ) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "El monto de pago no puede ser mayor al monto adjudicado restante",
        });

        return;
      }

      etapaContrato.append(
        "fecha_fin",
        `${this.entregaEtapa.fecha_recepcion} ${this.entregaEtapa.hora_recepcion}`
      );
      etapaContrato.append("monto", Number(this.entregaEtapa.pago));
      etapaContrato.append("tipo_movimiento", this.entregaEtapa.tipo_movimiento);
      etapaContrato.append("forma_pago", this.entregaEtapa.forma_pago);
      etapaContrato.append("id_movimiento_etapa", this.id_movimiento_etapa);
      etapaContrato.append("ponderacion", this.entregaEtapa.ponderacion);
      etapaContrato.append("no_valid_document", noValidDocument);

      if (this.documento_acta_recepcion !== null) {
        etapaContrato.append("documento_acta_recepcion", this.documento_acta_recepcion);
      } else if (
        !noValidDocument &&
        this.documento_acta_recepcion == null &&
        this.id_movimiento_etapa == null
      ) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "No has adjuntado el acta de recepción de la etapa",
        });

        return false;
      }

      if (this.documento_acta_inconformidad !== null)
        etapaContrato.append(
          "documento_acta_inconformidad",
          this.documento_acta_inconformidad
        );

      if (
        +this.entregaEtapa.pago > +this.montoRestante.monto_restante &&
        this.id_movimiento_etapa == null
      ) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "El monto de pago no puede ser mayor al monto restante",
        });

        return;
      }

      if (this.id_etapa === null) {
        const { status, data } = await this.services.ContratoService.postContratoEtapa(
          this.$route.params.idContrato,
          etapaContrato
        ).catch(() => {
          if (sendReturn) return false;
        });

        if ([200, 201].includes(status)) {
          this.dialogo_acta = false;
          this.id_etapa = data?.etapa?.id;
          this.id_movimiento_etapa = data?.movimiento?.id;
          this.temporalAlert({
            message: "Se ha generado la etapa con éxito",
            type: "success",
            show: true,
          });
          if (sendReturn) return true;
        } else {
          if (sendReturn) return false;
        }
      } else {
        // logica de entrega de etapa
        !this.entregaEtapa.comprobante_pago ||
          etapaContrato.append("comprobante_pago", this.entregaEtapa.comprobante_pago);
        !this.entregaEtapa.acta_recepcion ||
          etapaContrato.append("acta_recepcion", this.entregaEtapa.acta_recepcion);
        !this.entregaEtapa.acta_inconform ||
          etapaContrato.append("acta_inconformidad", this.entregaEtapa.acta_inconform);

        if (Number(this.entregaEtapa.pago) <= 0 || this.entregaEtapa.forma_pago == null) {
          this.temporalAlert({
            message: "Debe ingresar la forma de pago y la cantidad a pagar en la entrega",
            type: "warning",
            show: true,
          });

          return false;
        }

        const { status, data } =
          // await this.services.ContratoService.updateEtapa(
          await this.services.ContratoService.updateEtapaContrato(
            this.id_etapa,
            this.$route.params.idContrato,
            etapaContrato
          ).catch(() => {
            if (sendReturn) return false;
          });

        if ([200, 201].includes(status)) {
          this.id_movimiento_etapa = data?.movimiento?.id;

          this.temporalAlert({
            message: "Se ha actualizado la etapa con éxito",
            type: "success",
            show: true,
          });
          if (sendReturn) return true;
          this.regresar();
        } else {
          if (sendReturn) return false;
        }
        if (this.reseña.ponderacion !== null && this.idEvaluacion === null)
          await this.guardarEvaluacion();
        if (this.idEvaluacion !== null) await this.actualizarEvaluacion();
      }
    },
    regresar() {
      this.$router.replace({
        name: "seguimiento-orden-compra",
        params: { idContrato: this.$route.params.idContrato },
        query: { idProveedor: this.$route.query.idProveedor },
      });
    },

    OrderDocuments(array_documents) {
      if (array_documents.length > 0) {
        array_documents.forEach((element) => {
          const item = element.DocumentoOrden;
          if (item.id_tipo_documento_orden === 4) {
            this.info_comprobante_pago.status = true;
            this.info_comprobante_pago.id = item.id;
            this.info_comprobante_pago.ruta = item.ruta_archivo;
          } else if (item.id_tipo_documento_orden === 3) {
            this.info_acta_recepcion.status = true;
            this.info_acta_recepcion.id = item.id;
            this.info_acta_recepcion.ruta = item.ruta_archivo;
          } else if (item.id_tipo_documento_orden === 9) {
            this.info_acta_inconform.status = true;
            this.info_acta_inconform.id = item.id;
            this.info_acta_inconform.ruta = item.ruta_archivo;
          }
        });
      }
    },

    async DownloadFileFtn(documento_ruta) {
      const { data, status } = await this.services.PacProcesos.descargarDocumentoProceso({
        ruta: documento_ruta,
      });
      if (status == 200) {
        const blob = new Blob([data], {
          type: "application/pdf",
        });

        const file = new File([blob], "documento.pdf", {
          type: "application/pdf",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
      }
    },

    async getEtapa() {
      const { status, data } = await this.services.ContratoService.getContratoEtapa(
        this.id_etapa
      );

      if (status == 200) {
        this.entregaEtapa.ponderacion = data.calificacion
          ? Number(data.calificacion?.ponderacion)
          : null;
        this.datosEtapa.fecha_probable_recepcion = moment(data.fecha_inicio).format(
          "YYYY-MM-DD"
        );
        this.entregaEtapa.fecha_recepcion = data.fecha_fin
          ? moment(data.fecha_fin).format("YYYY-MM-DD")
          : null;
        this.entregaEtapa.hora_recepcion = data.fecha_fin
          ? moment(data.fecha_fin).format("HH:mm")
          : null;
        this.datosEtapa.futuro_pago = Number(data.cantidad_pagar);
        this.$refs.futuroPago.updateValue(this.datosEtapa.futuro_pago);
        this.datosEtapa.descripcion_entrega = data.descripcion_entrega;
        this.entregaEtapa.observaciones =
          data.descripcion != "null" ? data.descripcion : "";
        this.entregaEtapa.forma_pago = data.movimientos[0]?.detalle?.id_metodo_pago;
        this.entregaEtapa.tipo_movimiento = data.movimientos[0]?.id_tipo_movimiento;
        this.entregaEtapa.pago = data.movimientos[0]?.monto;
        this.$refs.pago.updateValue(this.entregaEtapa.pago);
        this.id_documento_acta = data.acta_recepcion[0]?.id_documento_contrato;
        this.id_movimiento_etapa = data.movimientos[0]?.id;
        this.OrderDocuments(data.documentos);
      }
    },

    async downloadDoc() {
      const response = await this.services.ContratoService.getActaRecepcion(
        this.id_documento_acta
      );
      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Acta_recepcion.pdf`);
      document.body.appendChild(link);
      link.click();
    },
    async getMontosContrato() {
      const { status, data } = await this.services.ContratoService.getMontosPagos(
        this.$route.params.idContrato
      );
      if (status == 200) {
        this.montoRestante = data;
      }
    },
    async guardarEvaluacion() {
      if (this.reseña.ponderacion === null) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Debe de elegir una ponderación",
        });
      } else {
        const proveedorEvaluacion = {};
        proveedorEvaluacion.id_proveedor = this.id_proveedor;
        proveedorEvaluacion.id_contrato = Number(this.$route.params.idContrato);
        proveedorEvaluacion.ponderacion = this.reseña.ponderacion;
        if (this.reseña.descripcion) {
          proveedorEvaluacion.evaluacion = this.reseña.descripcion;
        }

        const re = await this.services.ProveedorEvaluacion.postEvaluacionProveedor(
          proveedorEvaluacion
        );

        if (re.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Evaluación de proveedor creada",
          });
          this.reseña.ponderacion = null;
          this.reseña.descripcion = null;
          this.regresar();
        }
      }
    },
    async actualizarEvaluacion() {
      let data = {};
      data.ponderacion = this.reseña.ponderacion;
      data.evaluacion = this.reseña.descripcion;

      const { status } = await this.services.ProveedorEvaluacion.putEvaluacionProveedor(
        this.idEvaluacion,
        data
      );
      if (status === 200 || status === 201 || status === 204) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Evaluación del proveedor actualizada",
        });
        this.regresar();
      }
    },
    verificacionCdfContrato() {
      this.cdfLoading = true;
      this.services.ContratoService.verificacionCdfContrato(this.$route.params.idContrato)
        .then((response) => {
          if (response.status === 200) {
            this.cdf = response.data;
          }
        })
        .finally(() => {
          this.cdfLoading = false;
        });
    },

    // logica de lotes

    async FetchLotesEtapaFtn() {
      const { status, data } = await this.services.ContratoService.getLotesEtapa(
        this.$route.params.idContrato
      );
      if (status === 200) {
        this.list_lote_var = data.map((col) => ({
          ...col,
          sub_procesos: col.sub_procesos.map((col2) => ({
            ...col2,
            cantidad_total: col2.ggsp[0].cantidad - col2.ggsp[0].cantidad_recibida,
            monto_ganador: col2.ggsp[0].monto,
            cantidad_inicial: Number(col2.ggsp[0].cantidad),
            cantidad_recibida: col2.ggsp[0].cantidad_recibida,
            cantidad_inicial_bk: col2.ggsp[0].cantidad - col2.ggsp[0].cantidad_recibida,
          })),
        }));
        if (this.$route.params.idEtapa) {
          this.list_lote_var.forEach((lote) => {
            lote.sub_procesos.forEach((sub_proceso) => {
              sub_proceso.DetalleEtapaOrdenContratos.forEach((item_local) => {
                if (
                  sub_proceso.id == item_local.id_sub_proceso_obs &&
                  this.$route.params.idEtapa == item_local.id_etapa_contrato_orden
                ) {
                  // sub_proceso.cantidad_entregada = item_local.cantidad;
                  // sub_proceso.monto = item_local.monto.toFixed(8);
                  this.$forceUpdate();
                }
              });
            });
          });
        }
      }
    },

    FocusCantidadEntregadaFtn(item_param) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.list_lote_var.forEach((lote) => {
          lote.sub_procesos.forEach((sub_proceso) => {
            if (sub_proceso.id === item_param.id) {
              if (sub_proceso.cantidad_entregada == "") {
                sub_proceso.monto = "";
                if (
                  this.$route.params.idEtapa &&
                  this.list_lote_var[0].sub_procesos[0].ggsp[0].cantidad_recibida === 0
                ) {
                  sub_proceso.cantidad_total = sub_proceso.cantidad_inicial;
                } else if (
                  this.list_lote_var[0].sub_procesos[0].ggsp[0].cantidad_recibida === 0
                ) {
                  sub_proceso.cantidad_total = sub_proceso.cantidad_inicial_bk;
                }

                this.$forceUpdate();
                return;
              } else if (
                sub_proceso.cantidad_entregada <= 0 ||
                isNaN(sub_proceso.cantidad_entregada)
              ) {
                this.temporalAlert({
                  show: true,
                  type: "error",
                  message: "No puede utilizar una cantidad menor o igual que cero",
                });
                sub_proceso.cantidad_entregada = "";
                sub_proceso.monto = "";
                this.$forceUpdate();
                return;
              } else if (
                sub_proceso.cantidad_entregada > Number(sub_proceso.cantidad_total)
              ) {
                this.temporalAlert({
                  show: true,
                  type: "error",
                  message: "La cantidad entregada no puede ser mayor a la cantidad total",
                });
                sub_proceso.cantidad_entregada = "";
                sub_proceso.monto = "";
                this.$forceUpdate();
                return;
              }

              let precio_unitario = Number(
                sub_proceso.monto_ganador / sub_proceso.cantidad_inicial
              );

              // if (sub_proceso.cantidad_recibida > 0) {
              //   sub_proceso.cantidad_total =
              //     sub_proceso.cantidad_total -
              //     sub_proceso.cantidad_entregada;
              // } else {
              //   sub_proceso.cantidad_total =
              //     sub_proceso.cantidad_inicial -
              //     sub_proceso.cantidad_entregada;
              // }

              sub_proceso.monto = Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              }).format(sub_proceso.cantidad_entregada * precio_unitario);

              this.$forceUpdate();
              return;
            }
          });
        });
      }, 500);

      this.$forceUpdate();
    },

    FocusMontoFtn(item_param) {
      this.list_lote_var.forEach((lote) => {
        lote.sub_procesos.forEach((sub_proceso) => {
          if (sub_proceso.id === item_param.id) {
            if (sub_proceso.monto == "") {
              sub_proceso.cantidad_entregada = "";
              this.$forceUpdate();
              return;
            } else {
              if (sub_proceso.monto < 0 || isNaN(sub_proceso.monto)) {
                sub_proceso.monto = "";
                sub_proceso.cantidad_entregada = "";
                this.$forceUpdate();
                return;
              }

              let precio_unitario = Number(
                sub_proceso?.ggsp[0]?.monto / sub_proceso?.ggsp[0]?.cantidad
              ).toFixed(8);

              sub_proceso.cantidad_entregada = Number(
                sub_proceso.monto / precio_unitario
              ).toFixed();

              sub_proceso.monto = Number(
                sub_proceso.cantidad_entregada * precio_unitario
              ).toFixed(8);

              if (
                sub_proceso.cantidad_entregada > Number(sub_proceso.total_sub_proceso)
              ) {
                this.temporalAlert({
                  show: true,
                  type: "error",
                  message: "La cantidad entregada no puede ser mayor a la cantidad total",
                });
                sub_proceso.cantidad_entregada = "";
                sub_proceso.monto = "";
                this.$forceUpdate();
                return;
              }
              this.$forceUpdate();
              return;
            }
          }
        });
      });
      this.$forceUpdate();
    },
  },

  async created() {
    this.exp_lote_var = Array.from(
      { length: this.list_lote_var.length },
      (_, index) => index
    );
    this.getMontosContrato();
    this.getTiposPagos();

    this.contratoLoading = true;
    const { data } = await this.services.ContratoService.getContrato(
      this.$route.params.idContrato
    );

    this.contrato = data?.contrato;
    this.certificacion = data?.financiamiento;
    this.contratoLoading = false;

    if (this.$route.params?.idEtapa) {
      this.id_etapa = this.$route.params?.idEtapa;
      // Rellenar información con GET
      this.getEtapa();
      this.id_proveedor = data.contrato.id_referencia;
      if (data.contrato.evaluacion[0]) {
        this.reseña.ponderacion = Number(data.contrato.evaluacion[0].ponderacion);
        this.reseña.descripcion = data.contrato.evaluacion[0].evaluacion;
        this.idEvaluacion = data.contrato.evaluacion[0].id;
      }
    } else {
      this.id_etapa = null;
      this.id_movimiento_etapa = null;
    }

    this.getTipoMovimientoContrato();
    // Verificación de certificación de fondos
    this.verificacionCdfContrato();

    this.FetchLotesEtapaFtn();
  },
  watch: {
    list_lote_var: {
      deep: true,
      handler(newVal) {
        const cantidadTotal = newVal[0]?.sub_procesos[0]?.cantidad_total;
        if (cantidadTotal <= 0) {
          this.list_lote_var[0].sub_procesos[0].cantidad_entregada = null;
          this.list_lote_var[0].sub_procesos[0].monto = null;
        }
      },
    },
  },
};
</script>
